import { inject, Injectable } from '@angular/core'
import { _assert, _stringify } from '@naturalcycles/js-lib'
import { RecaptchaAction, VALID_RECAPTCHA_ACTION_REGEX } from '@naturalcycles/shared'
import { MixpanelService } from '@src/app/core/services/analytics/mixpanel.service'
import { SignalStore } from '@src/app/core/store/signalStore'
import { sentry } from '@src/app/core/util/sentry.util'
import { MixpanelEvent } from '@src/app/shared/typings/analytics'
import { env } from '@src/environments/environment'
import { ReCaptchaV3Service } from 'ng-recaptcha'
import { firstValueFrom } from 'rxjs'

/**
 * @description This service class is meant to make the reCAPTCHA token generation re-usable
 */
@Injectable({ providedIn: 'root' })
export class RecaptchaService {
  private store = inject(SignalStore)
  private reCaptchaV3Service = inject(ReCaptchaV3Service)
  private mixpanelService = inject(MixpanelService)

  /**
   * @description Should be used to generate reCAPTCHA token to be passed into the paymentInit call.
   * NOTE: The client will only receive the braintree client token if the reCAPTCHA token is valid.
   * NOTE2: This method has the side effect of storing the reCAPTCHA token in the store.
   *
   * `action` param only supports A-Za-z_, please make sure to not use unsupported characters.
   *
   * @returns reCAPTCHA token or undefined if reCAPTCHA is disabled
   */
  async generateAndStoreRecaptchaToken(action: RecaptchaAction): Promise<string | undefined> {
    _assert(this.isValidRecaptchaAction(action), `Invalid recaptcha action: ${action}`)

    if (!env.recaptchaEnabled) return
    try {
      const recaptchaToken = await firstValueFrom(this.reCaptchaV3Service.execute(action))
      this.store.$payment.update(s => ({
        ...s,
        recaptchaToken,
      }))
      return recaptchaToken
    } catch (err) {
      void this.mixpanelService.trackEvent(MixpanelEvent.RecaptchaFailed, {
        action,
        err: _stringify(err),
      })
      sentry.captureException(err)
      return
    }
  }

  isValidRecaptchaAction(action: string): boolean {
    return VALID_RECAPTCHA_ACTION_REGEX.test(action)
  }
}
